body {
  background: var(--background_1) linear-gradient(to top, var(--background_1), var(--background_2)) no-repeat;
  color: var(--text_color_2);
  overflow: auto;
}

.settings {
  #rules details p {
    background: var(--primary_1);
    color: var(--text_color);
  }

  .player-row {
    background: var(--menu_1a);
    color: var(--text_color);
  }

  .player-row:nth-of-type(3n+1) {
    background: var(--menu_2a);
  }

  .player-row:nth-of-type(3n+2) {
    background: var(--menu_3a);
  }

}

.player {
  background: var(--basic_1);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  .shoots {
    .shoot {
      background: var(--accent_4);

      &.total {
        background: var(--primary_2);
      }
    }
  }

  .line {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .player_info {
    .field {
    }
  }

  &.highlighted, &.highlighted2 {
    background: var(--primary_1);
    color: var(--text_color);

    .player_info {
      .shoot {
        &.total {
          background: var(--primary_2);
        }
      }
    }

    .line {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

#darts-table-plate {
  .number-text, .field-note {
    fill: var(--text_color);
  }

  .field-inactive {
    fill: none;
  }

  .field-color {
    fill: var(--table_normal);

    &.primary {
      fill: var(--table_normal_primary);
    }

    &.accent {
      fill: var(--table_normal_accent);
    }

    &.alert {
      fill: var(--table_normal_alert);
    }
  }

  .field-color-m {
    fill: var(--table_multi);

    &.primary {
      fill: var(--table_multi_primary);
    }

    &.accent {
      fill: var(--table_multi_accent);
    }

    &.alert {
      fill: var(--table_multi_alert);
    }
  }
}

.mat-dialog-container {
  background-color: var(--basic_0);
  color: var(--text_color);
}

.button {
  background-color: var(--basic_0);
  color: var(--text_color);

  &.primary {
    background-color: var(--primary_1);
  }

  &.icon {
    background: transparent;

    &.primary {
      color: var(--primary_1);
    }

    &.accent {
      color: var(--accent_3);
    }
  }
}
