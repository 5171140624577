.player {
  margin: 2px;
  border-radius: 4px;

  &.highlighted {
    &.highlighted2 {
      opacity: 1;
    }
  }

  &.highlighted2 {
    opacity: 0.6;
  }

  &.inactive {
    opacity: 0.4;
  }
}
