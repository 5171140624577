@import "styles/player-score";
@import "styles/common-element";
@import "styles/theming";

:root {
  --basic_0: #94b0baa8;
  --basic_1: #689ba4de;
  --primary_1: #3094b39e;
  --primary_2: #006064;
  --accent_3: #9e9e9e;
  --accent_4: #212121;
  --text_color: #fafafa;
  --text_color_2: #ffffff;
  --warn: #e3a5a0;
  --table_normal: #6767679c;
  --table_multi: #565656b8;
  --table_normal_primary: #186883c7;
  --table_multi_primary: #9d9471c7;
  --table_normal_accent: #7cacb1;
  --table_multi_accent: #5a8489;
  --table_normal_alert: #915656;
  --table_multi_alert: #af6565;
  --menu_1a: #365c708a;
  --menu_1b: #00000063;
  --menu_2a: #448ba6;
  --menu_2b: #6d94a3;
  --menu_3a: #92aaad;
  --menu_3b: #5caf9f;
  --background_1: #285769;
  --background_2: #71abb2;
  --mdc-theme-primary: rgba(255, 255, 255, 0.6);
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}

//* {
//  touch-action: manipulation;
//}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  touch-action: manipulation;
  width: 100%;
  overflow: hidden;
}

button {
  touch-action: manipulation;
}

.clear {
  clear: both;
}

#playgroundContent {
  width: 100vw;
  display: flex;
  flex-flow: column;

  .side-left {
    width: 100vw;
    transition: all 100ms ease-in-out;

    .player_container {
      .player {
        .player_info {
          .name {
            font-size: 14px;
          }

          .score {
            font-weight: normal;
            font-size: 18px;
          }

          .field-set, .line {
            display: none;
          }

          .shoots {
            .shoot {
              width: 25px;
              height: 20px;
              margin: 0 2px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .side-right {
    width: 100vw;
    transition: all 100ms ease-in-out;
    margin-top: 15px;
  }
}

.mobile {
  .dialog-container {
    width: 90vw;
  }

  #playgroundContent {

    .side-right {
      .number {
        .field-note {
          font-size: 9px;
        }

        mat-icon {
          display: none;
        }
      }
    }

    .settings {
      h1 {
        font-weight: 500;
        font-size: 18px;
      }

      h2 {
        display: none;
      }

      .add-player {
        .mat-form-field-wrapper {
          padding: 0;
        }
      }

      .player-name {
        font-weight: normal;
      }
    }
  }

}

.hidden-info {
  opacity: 0.3;
  position: absolute;
  bottom: 10px;
  left: 40px;
}

.hidden {
  opacity: 0;
}

mat-card {
  margin: 3px;
}
